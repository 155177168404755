//custom.js
/*$(function() {
  $('[data-tooltip="tooltip"]').tooltip();
  $('[data-toggle="tooltip"]').tooltip();
});

$(function() {
  $('[data-toggle="popover"]').popover();
});
*/

$(function () {
  $('[data-toggle="show_field"]').on('click', function (evt) {
    console.log("toggling field");
    if ($('#toggle_show').disabled) {
      $('#toggle_show').removeAttr('disabled');
    } else {
      $('#toggle_show').attr('disabled', 'true');
    }
  })
});

//File Load update selected text
$(document).on('ready turbolinks:load', function () {
  $('.custom-file-input').on('change', function () {
    let val = this.value.split("\\");
    let fne = val[val.length - 1];
    $('.custom-file-label').text(fne);
  });
});