import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["fundaccounts", "services", "vendors"]
  
  fund_selected(event){
    console.log("STIMMMED", event)
    
  }
  update(){
    
      let total = 1.0
      this.factorTargets.forEach( item => {
        total *= parseFloat(item.value)
      })
      this.totalTarget.value = total;
  }
}
