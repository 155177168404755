// src/controllers/hello_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["service", "fund"]
  fundSelected() {
    console.log("Hello, fund!",  event.currentTarget.value);
   
    let svcSelect = this.serviceTarget;
    fetch("/funds/"+event.currentTarget.value+".json") // Call the fetch function passing the url of the API as a parameter
      .then( data => data.json()) //console.log("DATA RESP:", data))
      .then( jsondata => this.populateServiceOptions(jsondata, this.serviceTarget)) 
      .catch(data => alert("uhoh?", data) );    
  }

  populateServiceOptions( data, selectBox){    
    selectBox.innerHTML = '';
    data.forEach(item => {
      const opt = document.createElement('option');
      opt.value = item.id;
      opt.text = item.servicename;
      //opt.innerHTML = item[this.data.get('displayAttribute')];
      //opt.selected = parseInt(targetId) === item.id;
      selectBox.appendChild(opt);
    })
  }
  //--------------------
}