// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require jquery3
//= require popper
//= require bootstrap-sprockets
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import "jquery"

import "bootstrap"
//import "jquery.steps";
import "@fortawesome/fontawesome-pro/js/all";
require('datatables.net-bs5');
import $ from 'jquery';
global.$ = jQuery;


//$.fn.DataTable = dt
import "jquery-validation"
//import 'bootstrap';
//require( 'datatables.net' );

//require('datatables.net-scroller-bs4');
//require( 'datatables.net-dt' );
//Srequire('datatables.net-buttons-bs4');
//require( 'datatables.net-buttons/js/buttons.print.js' )();

//require("datatables.net-bs4/css/dataTables.bootstrap4.min.css")
require('packs/custom');


const dataTables = [];
document.addEventListener("turbolinks:load", () => {
  if (dataTables.length === 0 && $('.data-table').length !== 0) {
    $('.data-table').each((_, element) => {
      dataTables.push($(element).DataTable({
        pageLength: 50
      }));
    });
  }
});




document.addEventListener("turbolinks:before-cache", () => {
  while (dataTables.length !== 0) {
    dataTables.pop().destroy();
  }
});

// src/application.js
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

import "bootstrap"
import "../stylesheets/application"
import "../custom/jquery-steps/jquery.steps.min";

//document.addEventListener("turbolinks:load", () => {
//  $('[data-toggle="tooltip"]').tooltip()
//  $('[data-toggle="popover"]').popover()
//
//})


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
